<script setup>
    import { onMounted, reactive, toRefs } from 'vue'
    import { useRoute, useRouter } from 'vue-router/composables'
    import {articleLists} from '../api/index'
    import moment from 'moment'

    const router = useRouter()


    const state = reactive({
        lists:[],
        detail:{},
        total:0,
        page:1,
        pageSize:10,
    })


    // 获取列表
    const getLists = ()=>{
        const data={
            pageNo:state.page,
            pageSize:state.pageSize,
            categoryId:2
        }
         articleLists(data).then(({data})=>{
            const _data = data?.list || []

            _data.forEach(element => {
                element.year = moment(element.createTime).format('YYYY')
                element.month = moment(element.createTime).format('MM')
                element.day = moment(element.createTime).format('DD')
            });

            state.lists = _data
            state.detail = _data[0] || {}
            state.total = data.total
        })
    }

    // 分页页数更改的时候
    const handleCurrentChange =(val)=>{
        state.page = val
        getLists()
    }

    const handleClick =(item)=>{
        if(item.wechatUrl){
            window.open(item.wechatUrl,'_blank')
        }else {
            router.push('/information/detail?id='+item.id)
        }
    }

    const {lists,detail,total,page} = toRefs(state)


    onMounted(() => {

     getLists()

    })

</script>

<template>
    <div>
        <!--   详情     -->
        <div v-if="lists.length >0" class="info-box flex flex-space-between" @click="handleClick(detail)">
           <div class="info-box-img">
            <!-- <img :src="detail.picUrl" /> -->
                <el-image :src="detail.picUrl"></el-image>
            </div>
           <div class="info-box-text">
               <p class="info-box-text-time">{{detail.month}}.{{detail.day}}<i>{{detail.year}}</i> </p>
               <h2>{{detail.title}}</h2>
               <p class="info-box-text-desc">{{detail.introduction}}</p>
           </div>
        </div>
        <!--   列表     -->
        <div v-if="lists.length >0" class="info-lists">
            <ul>
                <li v-for="(item,i) in lists" :key="item.id" v-show="i>0" class="flex flex-align-center" @click="handleClick(item)">
                    <div class="zx-left">
                        <h4>{{item.month}}.{{item.day}}</h4>
                        <p>{{item.year}}</p>
                    </div>
                    <i/>
                    <div class="line-clamp1 information-text">{{item.title}}</div>
                </li>
            </ul>

            <div class="tc">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    class="tc"
                    prev-text="上一页"
                    next-text="下一页"
                    @current-change="handleCurrentChange"
                    :current-page.sync="page"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <div class="none-data" v-else>暂无数据~</div>


    </div>
</template>

<style scoped lang="scss">
.none-data{
    text-align: center;
    padding: 50px;
}
.info-box{
    padding: 57px 0;
    width: 1149px;
    margin: auto;
    .info-box-img{
        flex:0 0 497px;
        height:278px;
        overflow: hidden;
        //background: #42b983;
        margin-right: 55px;
        img{
            width: 100%;
        }
    }
    .info-box-text{
        .info-box-text-time{
            font-weight:700;
            color:#434343;
            font-size:24px;
            i{
                font-style: normal;
                font-weight:700;
                color:#b2b2b2;
                font-size:14px;
            }
        }
        h2{
            color:#434343;
            font-size:24px;
            margin-top: 28px;
            margin-bottom: 39px;
        }
        .info-box-text-desc{
            color:#585858;
            font-size:14px;
        }
    }
}
.info-lists{
    background: #ffffff;
    padding-top: 39px;
    padding-bottom: 132px;
}
ul {
    color:#434343;
    width: 1149px;
    margin: auto;
    padding-bottom: 129px;

    li {
        padding-bottom: 15px;
        border-bottom: 1px dotted rgba(#707070, .4);
        padding-top: 15px;

        .information-text {
            width: calc(100% - 130px);
        }
    }

    .zx-left {
        h4 {
            font-weight: 700;
            color: #434343;
            font-size: 24px;
        }

        p {
            font-weight: 700;
            color:#b2b2b2;
            font-size: 14px;
        }
    }

    i {
        height: 40px;
        width: 1px;
        background: #707070;
        display: inline-block;
        margin: 0 51px 0 19px;
    }
}
</style>
